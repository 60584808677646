<span class="profileContainer">
 
    <div class="row" *ngIf="userInput == null">
        <div class="col s12 center-align">
            <br><br>
            <div id="preloader"></div>
            <br>
            <div class="center-align">Bitte warten...</div>
        </div> 
    </div>

    <span ass="row" *ngIf="userInput != null">
        <span *ngIf="action == 'view'">
        <div class="row">
            <div class="col s12 pad-0">

                <div class="editprof-img">
                    <div class="img-wrap circle">
                        <img class="circle" src="{{user.profileimage}}" *ngIf="user.profileimage != null">
                        <img class="circle" src="assets/images/user-placeholder.jpg" *ngIf="user.profileimage == null">
                        <i class="mdi mdi-pencil prefix" (click)="edit()"></i>
                    </div>
                </div>

            </div>
        </div>
        </span>

        <span *ngIf="action == 'edit'">
            <div class="row">
                <div class="col s12 pad-0">

                    <div class="card profileImagesCard" *ngIf="profileImage != null">
        
                        <div class="card-content">
                            <span class="card-title"></span>
                            
                            <div class="row profileImagesRow">
                                <div class="col s12 center-align" *ngIf="uploadProcessing === true">
                                    <br><br>
                                    <div id="preloader"></div>
                                    <br>
                                    <div class="center-align">Ihre Daten werden hochgeladen ({{uploadProgress}}%)...</div>
        
                                    <div class="progress teal lighten-4 pad-top-15">
                                        <div class="determinate teal lighten-2" style="width: {{uploadProgress}}%"></div>
                                    </div>
                                </div> 
                                
                                <span *ngIf="uploadProcessing === false">
                                <div class="col s10 offset-s1 m6 offset-m3 center-align">
                                    <image-cropper
                                        [imageChangedEvent]="imageChangedEvent"
                                        [resizeToWidth] = "600"
                                        [maintainAspectRatio]="true"
                                        [aspectRatio]="4 / 4"
                                        format="jpeg"
                                        (imageCropped)="imageCropped($event)"
                                        (imageLoaded)="imageLoaded()"
                                        (cropperReady)="cropperReady()"
                                        (loadImageFailed)="loadImageFailed()"
                                    ></image-cropper>

                                    <!--img src="{{profileImage}}" lass="pad-top-15" alt="{{profileImagesMetadata.name}}" class="" style="width: 200px; height: auto;"-->
                                </div>
                                <div class="input-field col s12 center-align">
                                    <a class="waves-effect waves-dark btn-large white teal-text" (click)="cancel();"><i class="mdi mdi-close-box-outline left"></i>İptal etmek</a>
                                    &nbsp;
                                    <a class="waves-effect waves-light btn-large primary-bg" (click)="upload();"><i class="mdi mdi-upload left"></i>Hochladen</a>
                                </div>
                                </span>
                            </div>
                        </div>
        
                    </div>
        
                    <div class="row pad-top-15" *ngIf="uploadProcessing == false">
            
                        <div class="col s12 dropify-wrapper">
                            <form [formGroup]="profileImagesForm">
                                <div class="dropify-message teal-text">
                                    <i class="mdi mdi-cloud-outline icon"></i>
                                    <p [ngClass]="{'red-text': submitted && pif.file.errors }">Datei per Drag & Drop oder Klick hochladen</p>
                                    <p *ngIf="submitted && pif.file.invalid" [ngClass]="{'red-text': submitted && pif.file.errors }"> 
                                        Eine Auswahl ist notwendig!
                                    </p>
                                </div>
                                <input id="profileImageInputfield" type="file" accept=".jpg,.jpeg" formControlName="file" (change)="onDocumentFileChange($event)" class="dropify waves-effect waves-dark btn-large white teal-text"/>
                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </span>
    </span>

</span>