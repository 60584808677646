<div class="container" gaCategory="page_login">
    <div class="section">
    
        <div class="row ">
            <div class="col s12 pad-0">

                <h2 class="bot-20 sec-tit">Giriş yapmak</h2>

                Lütfen giriş bilgilerinizi girin ve giriş yap'a tıklayın!<br><br>

                <form [formGroup]="loginForm" (submit)="login()">

                    <div class="card darken-1">
                        <div class="card-content">
                            <span class="card-title"></span>

                            <div class="row">
                                <div class="input-field col s10 offset-s1">
                                    <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'invalid': submitted && f.email.errors }" />
                                    <label for="email">E-Mail</label>
                                    <span *ngIf="submitted && f.email.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                                </div>
                            </div>
        
                            <div class="row">
                                <div class="input-field col s10 offset-s1">
                                    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'invalid': submitted && f.password.errors }" />
                                    <label for="password">Şifre</label>
                                    <span *ngIf="submitted && f.password.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                                </div>
                            </div>
                        </div>

                        <div class="card-action right-align">
                            <a class="waves-effect waves-light btn-large bg-primary" (click)="login()"><i class="mdi mdi-key left"></i>Giriş yapmak</a>
                        </div>
                    </div>

                </form>

            </div>
        </div>

    </div>
</div>
