import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { CallService } from '@app/services/call.service';
import { ConfigService } from '@app/services/config.service';
import { StorageService } from '@app/services/storage.service';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-calls-page',
  templateUrl: './calls-page.component.html',
  styleUrls: ['./calls-page.component.scss']
})
export class CallsPageComponent extends CustomComponent implements OnInit {

  calls = null;
  totalCalls = null;
  pageSize = 10;
  page = 0;
  pageSizeOptions: number[] = [10, 25, 50];

  callStatus = new FormControl('Open');
  callStatusData = [
    {'key': 'Tümü', 'value': ''},
    {'key': 'Düzenlenmemiş', 'value': 'Open'},
    {'key': 'Düzenlendi', 'value': 'Done'}
  ]

  callIntent = new FormControl('');
  callIntentData = [
    {'key': 'Tümü', 'value': ''}
  ]

  constructor(
    protected override storageService: StorageService,
    protected callService: CallService,
    protected configService: ConfigService,
    protected override router: Router
  ) { 

    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.loadCalls();
    this.loadDialogConfig();
  }

  loadDialogConfig() {
    this.configService.getDialogConfig(this.user.guid)
    .subscribe(
      (getDialogConfigResponse) => {
        if (getDialogConfigResponse.config != null) {
          var dialogConfig = getDialogConfigResponse.config;

          dialogConfig.intents.forEach(intent => {
            this.callIntentData.push({'key': intent.name, 'value': intent.name});
          });
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  pageChanged(event) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    this.loadCalls();
  }

  callIntentChanged(event) {
    this.page = 0;
    this.loadCalls();
  }

  callStatusChanged(event) {
    this.page = 0;
    this.loadCalls();
  }

  loadCalls() {
    this.calls = null;
    this.callService.getCalls(this.user.guid, this.page, this.pageSize, this.callIntent.value, this.callStatus.value)
    .subscribe(
      (getCallsResponse) => {
        if (getCallsResponse.calls != null) {
          this.calls = getCallsResponse.calls;
          this.totalCalls = getCallsResponse.total;
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }

  @ViewChild('callsContent') set callsContent(element) {
    if (element) {
      M.AutoInit();
    }
  }
  
  callChanged(event) {
    this.loadCalls();
  }

}
