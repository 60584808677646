<!-- Regular page -->
<div>

    <!-- Details -->
    <div class="card darken-1">

        <div class="card-content">

            <span class="card-title">
                <span *ngIf="asset.guid == null">Anlegen</span>
                <span *ngIf="asset.guid != null">Düzenlemek</span>
            </span>

            <div class="row" *ngIf="asset == null">
                <div class="col s12 center-align">
                    <br><br>
                    <div id="preloader"></div>
                    <br>
                    <div class="center-align">Bitte warten...</div>
                </div> 
            </div>

            <div *ngIf="asset != null">
    
                <form [formGroup]="assetForm">

                    <div class="row">
                        <div class="col s4 m3">
                            <b>Typ:</b>
                        </div>
                        <div class="col s8 m9">
                            <select class="browser-default custom-select" formControlName="class" [ngClass]="{ 'invalid': submitted && f.class.errors }">
                                <option *ngFor="let class of classesData" [value]="class.key">{{class.value}}</option>
                            </select>
                            <div *ngIf="submitted && f.class.errors" class="error pad-top-5" data-error="Yanlış giriş" data-success="">Yanlış giriş</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s4 m3">
                            <b>Name:</b>
                        </div>
                        <div class="col s8 m9">
                            <input type=text formControlName="name" id="name" [ngClass]="{ 'invalid': submitted && f.name.errors }">
                            <span *ngIf="submitted && f.name.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s4 m3">
                            <b>Beschreibung:</b>
                        </div>
                        <div class="col s8 m9">
                            <textarea id="description" formControlName="description" [ngClass]="{ 'invalid': submitted && f.description.errors }">
                            </textarea>
                            <span *ngIf="submitted && f.description.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s4 m3">
                            <b>Nummer:</b>
                        </div>
                        <div class="col s8 m9">
                            <input type=text formControlName="internal_id" id="internal_id" [ngClass]="{ 'invalid': submitted && f.internal_id.errors }">
                            <span *ngIf="submitted && f.internal_id.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s4 m3">
                            <b>Ausleihdauer (Minuten):</b>
                        </div>
                        <div class="col s8 m9">
                            <input type=text formControlName="expiration_min" id="expiration_min" [ngClass]="{ 'invalid': submitted && f.expiration_min.errors }">
                            <span *ngIf="submitted && f.expiration_min.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s4 m3">
                            <b>Position/Brettnummer:</b>
                        </div>
                        <div class="col s8 m9">
                            <input type=text formControlName="location" id="location" [ngClass]="{ 'invalid': submitted && f.location.errors }">
                            <span *ngIf="submitted && f.location.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s4 m3">
                            <b>Teil eines Bunds:</b>
                        </div>
                        <div class="col s8 m9">
                            <label>
                                <input type="checkbox" formControlName="grouped" class="form-control" [ngClass]="{ 'invalid': submitted && f.grouped.errors }" />
                                <span></span>
                            </label>
                            <span *ngIf="submitted && f.grouped.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col s4 m3">
                            <b>Limitiert auf Mitarbeiter:</b>
                        </div>
                        <div class="col s8 m9">
                            <label>
                                <input type="checkbox" formControlName="restricted" class="form-control" [ngClass]="{ 'invalid': submitted && f.restricted.errors }" />
                                <span></span>
                            </label>
                            <span *ngIf="submitted && f.restricted.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                        </div>
                    </div>

                </form>
    

            </div>

        </div>

        <div class="card-action">

            <div class="row" *ngIf="action == 'edit'">
                <div class="col s6">
                    <a (click)="deleteAsset()" class="btn red" *ngIf="asset.guid"><i class="mdi mdi-delete-forever left"></i>Löschen</a>
                </div>
                <div class="col s6 right-align">
                    <a routerLink="/assets" class="btn white grey-text"><i class="mdi mdi-close left"></i>İptal etmek</a>
                    &nbsp;
                    <a (click)="save()" class="btn primary-bg"><i class="mdi mdi-content-save-outline left"></i>Kaydetmek</a>
                </div>
           </div>

        </div>

    </div>

</div>