<div class="container">
    <div class="section">
    
        <div class="row ">
            <div class="col s12 pad-0">

                <h2 class="bot-20 sec-tit">Çıkış Yap</h2>

                Sie werden ausgeloggt!<br><br>

            </div>
        </div>

    </div>
</div>
