<div class="card primary">

    <div class="card-content">

        <form [formGroup]="employeeCheckinForm">

            <div class="row">
                <div class="col s12">
                    Nachfolgend haben Sie die Möglichkeit Mitarbeiter persönlich einzuchecken.
                    QR-Code einscannen, Nachweis manuell überprüfen und einchecken klicken.
                </div>
            </div>

            <div class="row">
                <div class="col s12 m3">
                    <b>Mitarbeiter/in</b>
                    <div class="show-on-small"><br></div>
                </div>

                <div class="col s10 m7">
                    <input matInput placeholder="" aria-label="" [matAutocomplete]="auto" (keyup)="keyupContact($event)">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                        <mat-option *ngFor="let employee of employees" [value]="employee.guid"
                            (onSelectionChange)="onClickSuggestion($event, employee)">
                        <span>{{employee.firstname}} {{employee.lastname}}<span *ngIf="employee.internal_id" class="grey-text"> ({{employee.internal_id}})</span></span>
                        </mat-option>
                    </ng-container>
                    </mat-autocomplete>
                </div>

                <div class="col s2 m2 right-align">
                    <button class="waves-effect waves-light btn white grey-text" (click)="scan()"><i class="mdi mdi-qrcode-scan"></i></button>
                </div>
            </div>

            <div class="row">
                <div class="col s10 m7 push-m3">
                    <div *ngIf="employee == null">
                        <div *ngIf="submitted && f.checked.errors" class="error" data-error="Yanlış giriş" data-success="">Keine Auswahl</div>
                        <div *ngIf="!submitted">Keine Auswahl</div>
                    </div>
                    <div *ngIf="employee != null">{{employee.firstname}} {{employee.lastname}}</div>
                </div>

                <div class="col s2 m2 push-m3 right-align">
                    <button class="waves-effect waves-light btn white grey-text" (click)="setEmployee(null)"><i class="mdi mdi-close-circle-outline"></i></button>
                </div>
            </div>

            <div class="row">
                <div class="col s10 m3">
                    <b>Nachweis überprüft?</b>
                </div>
                <div class="col s2 m9">
                    <label>
                        <input type="checkbox" formControlName="checked" class="form-control" [ngClass]="{ 'invalid': submitted && f.checked.errors }" />
                        <span></span>
                    </label>
                </div>
                <div class="col s12 m9 push-m3">
                    <div *ngIf="submitted && f.checked.errors" class="error" data-error="Yanlış giriş" data-success="">Der Nachweis muss überprüft werden.</div>
                </div>
            </div>

        </form>

    </div>

    <div class="card-action right-align">
        <button class="waves-effect waves-light btn-large white grey-text" routerLink="/checkin">İptal etmek</button>
        &nbsp;
        <a class="waves-effect waves-light btn-large primary-bg" (click)="save()"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>Einchecken</a>
    </div>

</div>
