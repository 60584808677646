import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from '@app/services/report.service';

@Component({
  selector: 'app-report-date-selector',
  templateUrl: './report-date-selector.component.html',
  styleUrls: ['./report-date-selector.component.css']
})
export class ReportDateSelectorComponent implements OnInit {

  constructor(
    protected reportService: ReportService,
  ) { }

  dateRanges = ['Stunde', 'Tag', 'Woche', 'Monat', 'Jahr'];
  dateRangeSelection = 1;

  @Input() date;
  @Input() todate;

  ngOnInit(): void {
  }

  getDataRange(): string {
    return this.dateRanges[this.dateRangeSelection];
  }

  setDataRange(index: number) {
    this.dateRangeSelection = index;
  }

  clickRangeRight(): void {
    this.dateRangeSelection = this.dateRangeSelection + 1;
    if (this.dateRangeSelection > this.dateRanges.length-1) { this.dateRangeSelection = 0; } 

    this.date = new Date();
    this.todate = new Date();
    this.flattenDates(); 
  }

  clickRangeLeft(): void {
    this.dateRangeSelection = this.dateRangeSelection - 1;
    if (this.dateRangeSelection < 0) { this.dateRangeSelection = this.dateRanges.length-1; } 

    this.date = new Date();
    this.todate = new Date();
    this.flattenDates(); 
  }
  
  flattenDates(): void {
    switch (this.dateRangeSelection) {
      case 0:
        this.date.setSeconds(0);
        this.date.setMinutes(0);

        this.todate = new Date();
        this.todate.setTime(this.date.getTime() + 1*60*60*1000);
        break;
      case 1:
        this.date.setSeconds(0);
        this.date.setMinutes(0);
        this.date.setHours(0);
        
        this.todate = new Date();
        this.todate.setTime(this.date.getTime() + 24*60*60*1000);
        break;
      case 2:
        this.todate = new Date();
        this.todate.setTime(this.date.getTime() + 7*24*60*60*1000);
        break;
      case 3:
        this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 1, 0, 0, 0);
        this.todate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 1, 0, 0, 0);
        break;
      case 4:
        this.date = new Date(this.date.getFullYear(), 0, 1, 0, 0, 0);
        this.todate = new Date(this.date.getFullYear() + 1, this.date.getMonth(), 1, 0, 0, 0);
        break;
    }   
    
    var dates = [this.date, this.todate];
    this.reportService.datesChanged.emit(dates);
  }

  clickDateRight(): void {

    var result = new Date(this.date);
    switch (this.dateRangeSelection) {
      case 0:
        result.setTime(result.getTime() + 1*60*60*1000);
        break;
      case 1:
        result.setTime(result.getTime() + 24*1*60*60*1000);
        break;
      case 2:
        result.setTime(result.getTime() + 7*24*1*60*60*1000);
        break;
      case 3:
        result.setMonth(result.getMonth() + 1);
        break;
      case 4:
        result.setFullYear(result.getFullYear() + 1);
        break;
      }

    this.date = result;
    this.flattenDates();
  }

  clickDateLeft(): void {
    var result = new Date(this.date);

    switch (this.dateRangeSelection) {
      case 0:
        result.setTime(result.getTime() - 1*60*60*1000);
        break;
      case 1:
        result.setTime(result.getTime() - 24*1*60*60*1000);
        break;
      case 2:
        result.setTime(result.getTime() - 7*24*1*60*60*1000);
        break;
      case 3:
        result.setMonth(result.getMonth() - 1);
        break;
      case 4:
        result.setFullYear(result.getFullYear() - 1);
        break;
    }

    this.date = result;
    this.flattenDates();
  }

}
