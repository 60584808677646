<div class="row" *ngIf="reportData == null">
    <div class="col s12 center-align">
        <br><br>
        <div id="preloader"></div>
        <br>
        <div class="center-align">Bitte warten...</div>
    </div> 
</div>

<div class="row" *ngIf="reportData != null">
    <div class="card-panel right-align">
        <button class="btn waves-effect waves-light white grey-text margin-right-5" type="submit" name="action" title="Reload" (click)="reload()">
            <i class="mdi mdi-reload"></i>
        </button>
        <button class="btn waves-effect waves-light white grey-text" type="submit" name="action" title="Vergleichen" (click)="addToHistory()">
            <i class="mdi mdi-timetable"></i>
        </button>
    </div>

    <table id="resultTable" class="responsive-table" *ngIf="reportData != null">
        <thead>
            <tr>
                <th>Attribut</th>
                <th>Zeitraum</th>
                <th>Gesamt</th>
            </tr>
        <tbody>
            <!--tr *ngIf="reportData.document != null">
                <td>Befragungen</td>
                <td>{{reportData.document.count}}</td>
                <td>{{reportData.document.total}}</td>
                <td></td>
            </tr-->
            <tr *ngIf="reportData.document_station != null">
                <td><a routerLink="/stats/station" routerLinkActive="active">Station</a></td>
                <td>{{reportData.document_station.count}}</td>
                <td>{{reportData.document_station.total}}</td>
                <td class="right-align"><a routerLink="/stats/station" routerLinkActive="active">Details <i class="mdi mdi-arrow-right"></i></a></td>
            </tr>
            <tr *ngIf="reportData.document_zma != null">
                <td><a routerLink="/stats/zma" routerLinkActive="active">ZMA</a></td>
                <td>{{reportData.document_zma.count}}</td>
                <td>{{reportData.document_zma.total}}</td>
                <td class="right-align"><a routerLink="/stats/zma" routerLinkActive="active">Details <i class="mdi mdi-arrow-right"></i></a></td>
            </tr>
            <tr *ngIf="reportData.document_zz != null">
                <td><a routerLink="/stats/zz" routerLinkActive="active">ZZ</a></td>
                <td>{{reportData.document_zz.count}}</td>
                <td>{{reportData.document_zz.total}}</td>
                <td class="right-align"><a routerLink="/stats/zz" routerLinkActive="active">Details <i class="mdi mdi-arrow-right"></i></a></td>
            </tr>
            <tr *ngIf="reportData.assetlog != null">
                <td><a routerLink="/stats/assets" routerLinkActive="active">Objekte</a></td>
                <td>{{reportData.assetlog.count}}</td>
                <td>{{reportData.assetlog.total}}</td>
                <td class="right-align"><a routerLink="/stats/assets" routerLinkActive="active">Details <i class="mdi mdi-arrow-right"></i></a></td>
            </tr>
            <tr *ngIf="reportData.employeecheckin != null">
                <td><a routerLink="/stats/checkins" routerLinkActive="active">Check-Ins</a></td>
                <td>{{reportData.employeecheckin.count}}</td>
                <td>{{reportData.employeecheckin.total}}</td>
                <td class="right-align"><a routerLink="/stats/checkins" routerLinkActive="active">Details <i class="mdi mdi-arrow-right"></i></a></td>
            </tr>
        </tbody>
    </table>
</div>

<div class="row" *ngIf="historyData && !isEmptyObject(historyData)">
    <h1>Vergleichstabellen</h1>

    <div *ngFor="let historyItem of historyData | keyvalue; let i = index;">
        <div class="card-panel">
            <div style="float: left">
                <span [innerHTML]="historyItem.key"></span>
            </div>

            <div style="float: right">
                <button class="btn waves-effect waves-light white grey-text" type="submit" name="action" title="Löschen" (click)="deleteHistory(historyItem.key)">
                    <i class="mdi mdi-delete-forever"></i>
                </button>
            </div>
            <br clear="all">
        </div>

        <div [innerHTML]="historyItem.value"></div>
    </div>

</div>
