<!-- Buttons -->
<div class="card-panel">

    <div class="left">
        <div *ngIf="action == 'view'">
            <a (click)="setAction('edit')" class="btn white grey-text"><i class="mdi mdi-playlist-edit left"></i>Düzenlemek</a>
            &nbsp;
            <a (click)="lockDocument()" class="btn primary-bg" *ngIf="document.status == 0"><i class="mdi mdi-lock-outline left"></i>Freigeben</a>
        </div>
    
        <div *ngIf="action == 'edit'">
            <a (click)="cancel()" class="btn white grey-text"><i class="mdi mdi-close left"></i>İptal etmek</a>
            &nbsp;
            <a (click)="save()" class="btn primary-bg"><i class="mdi mdi-content-save-outline left"></i>Kaydetmek</a>
            <mat-checkbox class="pad-left-10" [(ngModel)]="autolockDocument">Dokument freigeben?</mat-checkbox>
        </div>
    </div>

    <div class="right">
        <a (click)="unsetDocument()" class="btn primary-bg" *ngIf="action == 'view'"><i class="mdi mdi-close-box-outline"></i></a>
    </div>

    <br clear="all">
</div>

<!-- Images -->
<div class="card-panel">

    <div class="center-align">
        <a (click)="loadScans()" class="btn white grey-text" *ngIf="showScans == false">
            <i class="mdi mdi-file-image"></i>Bilder laden
        </a>
        <a (click)="toggleScans()" class="btn white grey-text" *ngIf="showScans == true">
            <i class="mdi mdi-file-image"></i>Bilder verstecken
        </a>
    </div>

    <div class="row" *ngIf="showScans == true">
        <br>
        <div class="col s6">
            <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="page0">
        </div>
        <div class="col s6">
            <img src="/assets/images/img_placeholder.jpg" class="responsive-img z-depth-1" id="page1">
        </div>
    </div>

</div>

<!-- Details -->
<div class="card darken-1">
    <div class="card-content">
        <span class="card-title">Bogen: {{document.name}} ({{document.class}})</span>

        <form [formGroup]="documentForm">
            
            <div class="row">
                <div class="col s12 m3">
                    <b>Gescannt am:</b>
                </div>
                <div class="col s12 m9">
                    {{getTimestamp(document.timestamp) | date:'medium':'':'de'}}
                </div>
            </div>

            <div class="row no-margin">
                <div class="col s12 l6">
                    <fieldset>
                        <legend>Behandlung</legend>

                        <div class="row no-margin">
                            <div class="col s12 m4">
                                <b>Datum</b>
                            </div>
                            <div class="col s12 m8">
                                <div *ngIf="action == 'view'">
                                    {{getTimestamp(document.content.treatment_date) | date:'mediumDate':'':'de'}}
                                </div>
                                <div *ngIf="action == 'edit'" class="input-field" #actionChanged>
                                    <input type="text" formControlName="treatment_date" id="treatment_date" class="datepicker">
                                    <label for="treatmentdate"></label>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </div>
            </div>

            <br>
            <div class="divider"></div>
            <br>
            
            <div class="row headline-row">
                <div class="col s12 m12 l6"></div>
                <div class="col s12 l6">
                    <div class="col s2 center-align">Das Beste</div>
                    <div class="col s2 center-align">Sehr gut</div>
                    <div class="col s2 center-align">Gut</div>
                    <div class="col s2 center-align">Akzeptabel</div>
                    <div class="col s2 center-align">Schlecht</div>
                </div>
            </div>

            <app-checkbox-group label="1. Die Freundlichkeit am Tresen ist..." [action]="action" [form]="documentForm" name="q1"></app-checkbox-group>
            <!--hr class="q-divider"/-->
            <app-checkbox-group label="2. Die organisatorische Unterstützung durch das Pflegepersonal ist..." [action]="action" [form]="documentForm" name="q2"></app-checkbox-group>
            <app-checkbox-group label="3. Die Offenheit des Pflegepersonals für Anregungen und Verbesserungsvorschläge ist..." [form]="documentForm" name="q3" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="4. Die Freundlichkeit des Pflegepersonals ist..." [form]="documentForm" name="q4" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="5. Die Aufklärung durch die Ärzte zu meinen Fragen hisichtlich OP ist..." [form]="documentForm" name="q5" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="6. Die Aufklärung durch die Ärzte zu meinen Fragen hinsichtlich Narkose ist..." [form]="documentForm" name="q6" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="7. Die Freundlichkeit der Ärzte ist..." [form]="documentForm" name="q7" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="8. Meine Erfahrungen mit der Funktionsabteilung (EKG, Lungenfunktionstest etc.) sind..." [form]="documentForm" name="q8" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="9. Meine Erfahrungen mit der Röntgenabteilung sind..." [form]="documentForm" name="q9" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="10. Die Angemessenheit der Informationen für meine Angehörigen sind..." [form]="documentForm" name="q10" [action]="action"></app-checkbox-group>

            <br>
            <div class="row no-margin">
                <div class="col s12 m6">
                    Wie lange war Ihre Wartezeit zur (vor-) stationären Aufnahme:
                </div>
                <div class="col s12 m6">
                    <div *ngIf="action == 'view'">
                        {{document.content.waitingtime_hours | number:'2.0'}}
                        <span *ngIf="document.content.waitingtime_hours != null">:</span>
                        {{document.content.waitingtime_minutes | number:'2.0'}}
                    </div>
                    <div *ngIf="action == 'edit'" class="input-field">
                        Stunden: <input type=text formControlName="waitingtime_hours" id="waitingtime_hours">
                        Minuten: <input type=text formControlName="waitingtime_minutes" id="waitingtime_minutes">
                    </div>
                </div>
            </div>

            <h2>Bereich Boarding</h2>

            <app-checkbox-group label="11. Die Auskunft des Pflegepersonals hinsichtlich des Ablaufs der OP-Vorbereitung ist..." [form]="documentForm" name="q11" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="12. Die Freundlichkeit des Pflegepersonals ist..." [form]="documentForm" name="q12" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="13. Die Wahrung meiner Intimsphäre durch das Pflegepersonal ist..." [form]="documentForm" name="q13" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="14. Die Aufklärung durch die Ärzte zu meinen Fragen hinsichtlich OP waren..." [form]="documentForm" name="q14" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="15. Die Angemessenheit der Informationen für meine Angehörigen ist..." [form]="documentForm" name="q15" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="16. Die Freundlichkeit der Ärzte ist..." [form]="documentForm" name="q16" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="17. Die hygienischen Verhältnisse sind..." [form]="documentForm" name="q17" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="18. Die Patientenzimmer sind..." [form]="documentForm" name="q18" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="19. Das Essen ist..." [form]="documentForm" name="q19" [action]="action"></app-checkbox-group>

            <h2>Zentrale Medizinische Aufnahme & Boarding</h2>

            <div class="row headline-row">
                <div class="col s12 m12 l6"></div>
                <div class="col s12 l6">
                    <div class="col s2 center-align">Immer</div>
                    <div class="col s2 center-align">Oft</div>
                    <div class="col s2 center-align">Selten</div>
                    <div class="col s2 center-align">Nie</div>
                    <div class="col s2 center-align"></div>
                </div>
            </div>

            <app-checkbox-group label="20. Das Personal desinfiziert sich die Hände, bevor es mich behandelt." size="4" [form]="documentForm" name="q20" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="21. Das Personal desinfiziert sich die Hände, nachdem es mich behandelt hat." size="4" [form]="documentForm" name="q21" [action]="action"></app-checkbox-group>
            <app-checkbox-group label="22. Das Personal zieht sich Handschuhe an, bevor es bei mir einen Verbandswechsel durchführt." size="4" [form]="documentForm" name="q22" [action]="action"></app-checkbox-group>

            <div class="row headline-row">
                <div class="col s12 m12 l6"></div>
                <div class="col s12 l6">
                    <div class="col s2 center-align">Das Beste</div>
                    <div class="col s2 center-align">Sehr gut</div>
                    <div class="col s2 center-align">Gut</div>
                    <div class="col s2 center-align">Akzeptabel</div>
                    <div class="col s2 center-align">Schlecht</div>
                </div>
            </div>

            <app-checkbox-group label="23. Ich werde die Waldkliniken weiterempfehlen als..." [form]="documentForm" name="q23" [action]="action"></app-checkbox-group>

            <br>
            <div class="divider"></div>
            <br>

            <div class="row">
                <div class="col s12">
                    <b>Wenn Sie an die Waldkliniken denken, was ist Ihr größtes Ärgernis?</b>
                </div>
                <div class="col s12">
                    <div *ngIf="action == 'view'">
                        {{document.content.comment_negative}}
                    </div>
                    <div *ngIf="action == 'edit'" class="input-field">
                        <input type=text formControlName="comment_negative" id="comment_negative">
                        <label for="comment_negative"></label>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col s12">
                    <b>Was schätzen Sie an den Waldkliniken am meisten?</b>
                </div>
                <div class="col s12">
                    <div *ngIf="action == 'view'">
                        {{document.content.comment_positive}}
                    </div>
                    <div *ngIf="action == 'edit'" class="input-field">
                        <input type=text formControlName="comment_positive" id="comment_positive">
                        <label for="comment_positive"></label>
                    </div>
                </div>
            </div>

        </form>

    </div>

</div>

<!-- Buttons -->
<div class="card-panel">

    <div class="left">
        <div *ngIf="action == 'view'">
        </div>
    
        <div *ngIf="action == 'edit'">
        </div>
    </div>

    <div class="right">
        <a (click)="deleteDocument()" class="btn red"><i class="mdi mdi-lock-outline left"></i>Löschen</a>
    </div>

    <br clear="all">
</div>
