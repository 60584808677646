<div class="container">
    <div class="section">

        <h1 class="bot-20 sec-tit">Ayarlar</h1>

        <app-config-dialogs></app-config-dialogs>
        
    </div>

</div>


