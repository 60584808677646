<div class="card profileContainer">
        
    <div class="card-content">
        <span class="card-title">Şifre</span>

        <div class="row" *ngIf="userInput == null || loading == true">
            <div class="col s12 center-align">
                <br><br>
                <div id="preloader"></div>
                <br>
                <div class="center-align">Bitte warten...</div>
            </div> 
        </div>
        
        <span *ngIf="userInput != null && loading == false">
            <form [formGroup]="accountPasswordForm" (submit)="submit()">

                <span *ngIf="action == 'view'">
                    <div class="row">
                        <div class="col s12 m6 l2">
                            <label for="email">Şifre</label>
                        </div>
                        <div class="input-field col s12 m6 l4">
                            ********
                        </div>
                    </div>
                </span>

                <span *ngIf="action == 'edit'">
                <div class="row">
                    <div class="col s12 m6 l2">
                        <label for="email">Yeni Şifre</label>
                    </div>
                    <div class="input-field col s12 m4">
                        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'invalid': submitted && apf.password.errors }" />
                        <span class="passwordSubtext">(8 karakter, en az bir sayı, bir küçük harf ve bir büyük harf)</span>
                        <span *ngIf="submitted && apf.password.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                        <span *ngIf="submitted && apf.password.hasError('minlength')" class="helper-text" data-error="Muss mindestens 8 Zeichen enthalten" data-success=""></span>
                        <span *ngIf="submitted && apf.password.hasError('hasNumber')" class="helper-text" data-error="Muss mindestens eine Zahl enthalten" data-success=""></span>
                        <span *ngIf="submitted && apf.password.hasError('hasSmallCase')" class="helper-text" data-error="Muss mindestens einen Kleinbuchstaben enthalten" data-success=""></span>
                        <span *ngIf="submitted && apf.password.hasError('hasCapitalCase')" class="helper-text" data-error="Muss mindestens einen Großbuchstaben enthalten" data-success=""></span>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m6 l2">
                        <label for="confirmPassword">Tekrarlama (şifre)</label>
                    </div>
                    <div class="input-field col s12 m4">
                        <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'invalid': submitted && apf.confirmPassword.errors }" />
                        <span *ngIf="submitted && apf.confirmPassword.errors" class="helper-text" data-error="Yanlış giriş" data-success=""></span>
                        <span *ngIf="submitted && apf.confirmPassword.hasError('NoPassswordMatch')" class="helper-text" data-error="Passwörter stimmen nicht überein" data-success=""></span>
                    </div>
                </div>

                </span>
            </form>
        </span>
    </div>

    <div class="card-action right-align">
        <span *ngIf="action == 'view'">
            <a (click)="edit()" class="waves-effect waves-dark btn-large white teal-text"><i class="mdi mdi-square-edit-outline left"></i>Şifreyi değiştir</a>
        </span>

        <span *ngIf="action == 'edit'">
            <a (click)="cancel();" class="waves-effect waves-dark btn-large white teal-text"><i class="mdi mdi-close-box-outline left"></i>İptal etmek</a>
            &nbsp;
            <a (click)="submit()" class="waves-effect waves-light btn-large primary-bg"><i class="mdi mdi-square-edit-outline left"></i>Kaydetmek</a>
        </span>
    </div>

</div>


