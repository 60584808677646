<div class="content-wrapper">

    <div class="row">
        <div class="col l6 m12 s12" *ngIf="hasPermissions('calls');">
            <a routerLink="/calls">
                <div class="setting-box z-depth-1 center hoverable">
                    <i class="mdi mdi-format-list-bulleted"></i>
                    <h2>Aramalar</h2>
                    Gelen aramaları yönetin.
                </div>
            </a>
        </div>
            
        <div class="col l6 m12 s12" *ngIf="hasPermissions('config');">
            <a routerLink="/config">
                <div class="setting-box z-depth-1 center hoverable">
                    <i class="mdi mdi-cellphone-cog"></i>
                    <h2>Ayarlar</h2>
                    Botunuzun ayarlarını yönetin.
                </div>
            </a>
        </div>
    </div>

    <div class="row">
        <div class="col l6 m12 s12">
        </div>
            
        <div class="col l6 m12 s12">
            <a routerLink="/account">
                <div class="setting-box z-depth-1 center hoverable">
                    <i class="mdi mdi-account-box-outline"></i>
                    <h2>Hesabım</h2>
                    Hesabınızı ve tarifinizi yönetin.
                </div>
            </a>
        </div>
    </div>

</div>
