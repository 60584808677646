import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { DocumentService } from '@app/services/document.service';
import { ReportService } from '@app/services/report.service';
import { StorageService } from '@app/services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stats-page',
  templateUrl: './stats-page.component.html',
  styleUrls: ['./stats-page.component.css']
})
export class StatsPageComponent extends CustomComponent implements OnInit {

  date = null;
  todate = null;
  private datesSub: Subscription;

  reportData = null;

  constructor(
    protected reportService: ReportService,
    protected override storageService: StorageService,
    protected documentService: DocumentService,
    protected override router: Router
  ) { 
    super(storageService, router, true);
  }

  ngOnInit(): void {
    this.date = new Date();
    this.date.setSeconds(0);
    this.date.setMinutes(0);
    this.date.setHours(0);

    this.todate = new Date();
    this.todate.setTime(this.todate.getTime() + 24*60*60*1000);

    this.loadReports();
    this.datesSub = this.reportService.datesChanged.subscribe(
      data => this.datesChanged(data)
    );
  }

  ngOnDestroy() {
    if (this.datesSub) {
      this.datesSub.unsubscribe()
    }
  }

  datesChanged(dates) {
    this.reportData = null;

    this.date = dates[0];
    this.todate = dates[1];
    this.loadReports();    
  }

  getDate(date) {
    var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    return isoDateTime.split('T')[0];
  }

  getTime(date) {
    return date.toTimeString().match(/\d{2}:\d{2}:\d{2}/)[0];
  }

  getDateTime(date) {
    return this.getDate(date) + " " + this.getTime(date);
  }

  loadReports(): void {
    var fromDate = this.getDateTime(this.date);
    var toDate = this.getDateTime(this.todate);

    var filter = {
//      'user_guid': this.user.guid,
      'from': fromDate,
      'to': toDate,
    };
    this.reportService.getReports(filter)
    .subscribe(
      (getReportsResponse) => {
      
        if (getReportsResponse.status == 200) {
          this.reportData = getReportsResponse.data;
          this.reportService.reportDataChanged.emit(this.reportData);
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      });
  }


}
