<div class="footer-menu">
    <ul>

        <li>&nbsp;</li>
        <li>
            <a routerLink="/calls" routerLinkActive="active" *ngIf="hasPermissions('calls');">
                <i class="mdi mdi-format-list-bulleted"></i>
                <span class="footer-menu-link">Aramalar</span>
            </a>
        </li>
        <li>
            <a routerLink="/config" routerLinkActive="active" *ngIf="hasPermissions('config');">
                <i class="mdi mdi-cellphone-cog"></i>
                <span class="footer-menu-link">Ayarlar</span>
            </a>
        </li>
        <li>
            <a routerLink="/account" routerLinkActive="active">
                <i class="mdi mdi-account-box-outline"></i>
                <span class="footer-menu-link">Konto</span>
            </a>
        </li>
        <li>&nbsp;</li>

    </ul>
</div>
