<div class="card-panel" *ngIf="assetlogs == null">
    <br><br>
    <div id="preloader"></div>
    <br>
    <div class="center-align">Bitte warten...</div>
</div>

<div *ngIf="assetlogs != null">
    
    <div class="assets card-panel" *ngIf="assetlogs.length > 0">
        <div class="title">Die Liste beinhaltet {{assetlogs.length}} Einträge.</div>
    </div>    
    
    <div class="assets card-panel" *ngIf="assetlogs.length == 0">
        <div class="title">Es sind keine Gegenstände ausgeliehen.</div>
    </div>

    <ul class="assetlogs" *ngIf="assetlogs.length > 0">
        <table id="assetslogTable" *ngIf="assetlogs != null">
            <thead>
                <tr>
                    <th>Objekt</th>
                    <th>Temas etmek</th>
                    <th class="hide-on-small-only">Zeit</th>
                    <th class="hide-on-small-only">Unterschrift</th>
                    <th></th>
                </tr>
                <tbody>
                    <tr *ngFor="let assetlog of assetlogs">
                        <td>
                            <i *ngIf="assetlog.asset_class == 'key'" class="mdi mdi-key-variant left" matTooltip="Schlüssel" matTooltipPosition="above" matTooltipClass="tooltip"></i>
                            <i *ngIf="assetlog.asset_class == 'keygroup'" class="mdi mdi-shape-circle-plus left" matTooltip="Schlüsselbund" matTooltipPosition="above" matTooltipClass="tooltip"></i>
                            <i *ngIf="assetlog.asset_class == 'misc'" class="mdi mdi-label-outline left" matTooltip="Sonstiges" matTooltipPosition="above" matTooltipClass="tooltip"></i>
                            {{assetlog.asset_name}}
                            <span class="hide-on-med-and-up"><br>
                                <i class="mdi mdi-clock-outline"></i>
                                {{getTimestamp(assetlog.timestamp) | date:'mediumTime':'':'de'}}
                            </span>
                        </td>
                        <td>{{assetlog.contact_name}}</td>
                        <td class="hide-on-small-only">{{getTimestamp(assetlog.timestamp) | date:'medium':'':'de'}}</td>
                        <td class="hide-on-small-only"><img src="{{getSignatureData(assetlog.signature)}}" height="50" *ngIf="assetlog.signature"></td>
                        <td class="right">
                            <a routerLink="/assets/return" [queryParams]="{assetlog_guid: assetlog.guid}" class="waves-effect waves-light btn white grey-text">
                                <i class="mdi mdi-checkbox-marked-outline"></i></a>
                        </td>
                    </tr>
                </tbody>                
        </table>
    </ul>

</div>

