import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { Router } from '@angular/router';
import { CustomComponent } from '@app/components/core/custom-component';
import { Call } from '@app/models/call.model';
import { CallService } from '@app/services/call.service';
import { StorageService } from '@app/services/storage.service';

declare let M: any;
declare let $: any;

@Component({
  selector: 'app-calllist',
  templateUrl: './calllist.component.html',
  styleUrls: ['./calllist.component.scss']
})
export class CalllistComponent extends CustomComponent implements OnInit {

  @Input() calls: Array<Call>;
  @Input() total: number;
  panelOpenState = false;
  @Output()
  callChangeEvent: EventEmitter<Call> = new EventEmitter<Call>();

  callIntentData = {
    'Unknown': '-',
    'Other': 'Sonstiges'
  }

  constructor(
    protected override storageService: StorageService,
    protected callService: CallService,
    protected override router: Router,
    protected datePipe: DatePipe
  ) { 

    super(storageService, router, true);
  }
 
  ngOnInit(): void {
  }

  open(call: Call) {
    call.status = 'Open';
    this.updateStatus(call);
  }

  archive(call: Call) {
    call.status = 'Done';
    this.updateStatus(call);
  }

  updateStatus(call: Call) {

    this.callService.updateStatus(this.user.guid, call)
    .subscribe(
      (genericCallResponse) => {
        if (genericCallResponse.call != null) {
          this.callChangeEvent.emit(genericCallResponse.call);
          M.toast({html: 'Der Eintrag wurde aktualisiert.', classes: 'primary-bg'});
        }
      },
      response => {
        console.log(response);
      },
      () => {
          //console.log("The POST observable is now completed.");
      }); 

  }

  isOpen(call: Call) {
    return call.status === 'Open';
  }

  isDone(call: Call) {
    return call.status === 'Done';
  }

  intentLabel(name) {
    if (this.callIntentData[name]) {
      return this.callIntentData[name];

    } else {
      return name;
    }
  }

  dateLabel(timestamp) {
    var dateFormat = 'dd.MM.yyyy';
    var today = new Date();
    var todayDateString = this.datePipe.transform(today.valueOf(), dateFormat);

    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    var yesterdayDateString = this.datePipe.transform(yesterday.valueOf(), dateFormat);

    var callDateString = this.datePipe.transform(new Date(timestamp), dateFormat);

    if (callDateString == todayDateString) {
      return "Heute";
    } else if (callDateString == yesterdayDateString) {
      return "Gestern";
    } else {
      return this.datePipe.transform(timestamp, dateFormat);
    }
  }

  stringToColor(str: string) {
    var color = '';

    if (str !== null && str !== undefined) {
      var hash = 0;
      str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
      })

      color = '#'
      for (var i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        color += value.toString(16).padStart(2, '0')
      }
    }

    return color;
  }

  complentaryColor(str: string) {
    var color = this.stringToColor(str);
    color = color.replace('#', '');
    var complement = 0xffffff ^ color.charCodeAt(0);
    return complement;
  }

  toHex(str: string) {
    str = str.replace(/[^#]/gi, '');
    var result = '';
    for (var i=0; i<str.length; i++) {
      result += str.charCodeAt(i).toString(16);
    }
    return '#' + result;
  }

  invertColor(str: string) {
    var hex = this.stringToColor(str);

    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }

    return '#' + (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase();
  }

  textColorBasedOnBackground(str: string) {
    var backgroundColor = this.stringToColor(str);

    backgroundColor = backgroundColor.substring(1);
    const r = parseInt(backgroundColor.substring(0,2), 16); // 0 ~ 255
    const g = parseInt(backgroundColor.substring(2,4), 16);
    const b = parseInt(backgroundColor.substring(4,6), 16);
  
    const srgb = [r / 255, g / 255, b / 255];
    const x = srgb.map((i) => {
      if (i <= 0.04045) {
        return i / 12.92;
      } else {
        return Math.pow((i + 0.055) / 1.055, 2.4);
      }
    });
  
    const L = 0.2126 * x[0] + 0.7152 * x[1] + 0.0722 * x[2];
    return L > 0.179 ? "#000" : "#fff";
  }

}
