<div class="card-panel" *ngIf="assetlog == null">
    <br><br>
    <div id="preloader"></div>
    <br>
    <div class="center-align">Bitte warten...</div>
</div>

<div class="card primary" *ngIf="assetlog != null">

    <div class="card-content">

        <div class="row">
            <div class="col s12 m3">
                <b>Gegenstand</b>
                <div class="show-on-small"><br></div>
            </div>
            
            <div class="col s12 m9" *ngIf="assetlog.asset == null">
                <span class="red-text">Keine Auswahl...</span>
            </div>
            <div class="col s12 m9" *ngIf="assetlog.asset != null">
                <span>{{assetlog.asset.name}}</span><br>
                <span class="grey-text" *ngIf="assetlog.asset.internal_id">({{assetlog.asset.internal_id}})</span>
            </div>
        </div>

        <div class="row">
            <div class="col s12 m3">
                <b>Ausgehändigt an</b>
                <div class="show-on-small"><br></div>
            </div>

            <div class="col s12 m9">
                <span class="red-text" *ngIf="assetlog.contact == null && assetlog.contact_name == null">Keine Auswahl...</span>
                <span *ngIf="assetlog.contact != null">{{assetlog.contact.firstname}} {{assetlog.contact.lastname}}</span>
                <span *ngIf="assetlog.contact == null && assetlog.contact_name != null">{{assetlog.contact_name}}</span>
            </div>
        </div>

        <div class="row">
            <div class="col s12 m3">
                <b>Angenommen von</b>
                <div class="show-on-small"><br></div>
            </div>

            <div class="col s10 m7">
                <span class="red-text" *ngIf="contact == null">Keine Auswahl...</span>
                <span *ngIf="contact != null">{{contact.firstname}} {{contact.lastname}}</span>
                
                <input matInput placeholder="" aria-label="" [matAutocomplete]="auto" (keyup)="keyupContact($event)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let contact of contacts" [value]="contact.guid"
                        (onSelectionChange)="onClickSuggestion($event, contact)">
                    <span>{{contact.firstname}} {{contact.lastname}}<span *ngIf="contact.company" class="grey-text"> ({{contact.company.name}})</span></span>
                    </mat-option>
                </ng-container>
                </mat-autocomplete>
            </div>

            <div class="col s2 m2 right-align">
                <button class="waves-effect waves-light btn white grey-text" (click)="scan('contact')"><i class="mdi mdi-qrcode-scan"></i></button>
            </div>
        </div>

    </div>

    <div class="card-action right-align">
        <button class="waves-effect waves-light btn-large white grey-text" routerLink="/assets/logs">İptal etmek</button>
        &nbsp;
        <a class="waves-effect waves-light btn-large primary-bg" (click)="save()"><i class="mdi mdi-arrow-right-bold-box-outline left"></i>Kaydetmek</a>
    </div>

</div>
