<div class="container">
    <div class="section">
    
        <h1 class="bot-20 sec-tit">Hesabım</h1>

        <app-account-image [userInput]="userInput"></app-account-image>

        <app-account-profile [userInput]="userInput"></app-account-profile>

        <app-account-password [userInput]="userInput"></app-account-password>

        <!--app-account-notifications [userInput]="userInput"></app-account-notifications-->

        <!--app-account-delete [userInput]="userInput"></app-account-delete-->

    </div>
</div>