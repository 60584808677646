import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Layouts
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { PlainLayoutComponent } from './layout/plain-layout/plain-layout.component';
// Pages
import { LoginComponent } from './components/account/login/login.component';
import { LogoutComponent } from './components/account/logout/logout.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
// Features
import { CallsPageComponent } from './pages/calls-page/calls-page.component';
import { ConfigPageComponent } from './pages/config-page/config-page.component';
// Documents
import { DocumentPageComponent } from './pages/document-page/document-page.component';
import { DocumentsPageComponent } from '@app/pages/documents-page/documents-page.component';
import { UploadDocumentPageComponent } from '@app/pages/upload-document-page/upload-document-page.component';
// Stats
import { StatsPageComponent } from '@app/pages/stats-page/stats-page.component';
import { StatsStationPageComponent } from './pages/stats-station-page/stats-station-page.component';
import { StatsZmaPageComponent } from './pages/stats-zma-page/stats-zma-page.component';
import { StatsZZPageComponent } from './pages/stats-zzpage/stats-zzpage.component';
import { StatsAssetsPageComponent } from './pages/stats-assets-page/stats-assets-page.component';
import { StatsCheckinsPageComponent } from './pages/stats-checkins-page/stats-checkins-page.component';
// Forms
import { FormsZZPageComponent } from '@app/pages/forms-zzpage/forms-zzpage.component';
import { FormsStationPageComponentComponent } from './pages/forms-station-page-component/forms-station-page-component.component';
import { FormsUniversalPageComponent } from './pages/forms-universal-page/forms-universal-page.component';
// Assets
import { AssetPageComponent } from '@app/pages/asset-page/asset-page.component';
import { AssetsPageComponent } from '@app/pages/assets-page/assets-page.component';
import { AssetsLogPageComponent } from './pages/assets-log-page/assets-log-page.component';
import { AssetsIssuePageComponent } from './pages/assets-issue-page/assets-issue-page.component';
import { AssetsReturnPageComponent } from './pages/assets-return-page/assets-return-page.component';
import { CompaniesPageComponent } from './pages/companies-page/companies-page.component';
// Employees
import { EmployeesPageComponent } from './pages/employees-page/employees-page.component';
import { CheckinPageComponent } from './pages/checkin-page/checkin-page.component';
import { CheckinSelfPageComponent } from './pages/checkin-self-page/checkin-self-page.component';
import { CheckinSupportedPageComponent } from './pages/checkin-supported-page/checkin-supported-page.component';
// Profile
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
// Content
import { ContentDiviPageComponent } from './pages/content-divi-page/content-divi-page.component';

const routes: Routes = [

  { 
    path: '', 
    component: MainLayoutComponent,
    children: [
      { path: 'calls', component: CallsPageComponent },
      { path: 'config', component: ConfigPageComponent },
      { path: 'documents', component: DocumentsPageComponent },
      { path: 'documents/upload', component: UploadDocumentPageComponent },
      { path: 'document/:guid', component: DocumentPageComponent },
      { path: 'stats', component: StatsPageComponent },
      { path: 'stats/station', component: StatsStationPageComponent },
      { path: 'stats/zma', component: StatsZmaPageComponent },
      { path: 'stats/zz', component: StatsZZPageComponent },
      { path: 'stats/assets', component: StatsAssetsPageComponent },
      { path: 'stats/checkins', component: StatsCheckinsPageComponent },
      { path: 'assets', component: AssetsPageComponent },
      { path: 'assets/logs', component: AssetsLogPageComponent },
      { path: 'assets/issue', component: AssetsIssuePageComponent },
      { path: 'assets/return', component: AssetsReturnPageComponent },
      { path: 'asset/new', component: AssetPageComponent },
      { path: 'asset/edit', component: AssetPageComponent },
      { path: 'checkin', component: CheckinPageComponent },
      { path: 'checkin/supported', component: CheckinSupportedPageComponent },
      { path: 'companies', component: CompaniesPageComponent },
      { path: 'employees', component: EmployeesPageComponent },
      { path: 'account', component: ProfilePageComponent },
      { path: 'home', component: HomePageComponent },
      { path: '',   redirectTo: '/home', pathMatch: 'full' }
    ]
  },

  { 
    path: '',
    component: PlainLayoutComponent, 
    children: [
      { path: 'forms/universal', component: FormsUniversalPageComponent },
      { path: 'forms/station', component: FormsStationPageComponentComponent },
      { path: 'forms/zz', component: FormsZZPageComponent },
      { path: 'content/divi', component: ContentDiviPageComponent },
      { path: 'checkin/self', component: CheckinSelfPageComponent },
    ]
  },

  // core pages
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
